<template>
  <billing-report :hcaiForm="true"></billing-report>
</template>

<script>
import { mapState } from "vuex";
import BillingReport from "../Billing.vue";
export default {
  name: "HCAIBillingReport",
  components: {
    BillingReport,
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },
};
</script>
